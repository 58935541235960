@import '../../styles/core.css';

.checkbox {
  width: 100%;
  display: flex;
  position: relative;

  &.disabled {
    & .checkbox__label {
      color: #BFBFBF;
    }
  }

  &__input {
    display: none;

    &:disabled {
      & + label {
        cursor: not-allowed;
      }

      & + label:after {
        display: none;
      }

      & + label:before {
        opacity: 0.32;
      }
    }

    &:checked + label:after {
      opacity: 1;
      visibility: visible;
    }
  }

  &__label {
    display: flex;
    font: 400 14px / 18px Manrope, sans-serif;
    color: #68686e;
    cursor: pointer;
    transition: all 0.3s ease;
    padding-left: 24px;

    &:hover {
      color: #191E80;
    }

    &:before,
    &:after {
      position: absolute;
      top: 1px;
      left: 0;
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
    }

    &:before {
      background-color: #fff;
      border: solid 1px #919199;
      opacity: 0.6;
    }

    &:after {
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s;
      background: url('/static/imgs/checkbox/check.svg') center no-repeat;
    }
  }
}

.tooltipIconWrap {
  margin-left: auto;
  margin-right: 8px;
  position: static !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid var(--colorBorderDefault);
  color: var(--colorTextLight);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  flex-shrink: 0;
}

.tooltipPopup {
  font-family: Manrope, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #656571 !important;
  padding: 16px !important;
  background-color: var(--colorWhite) !important;
  width: 264px !important;
  box-shadow: 0px 1px 4px rgba(18, 28, 41, 0.25), 0px 1px 4px rgba(18, 28, 41, 0.25) !important;
  box-sizing: border-box !important;

  &:before {
    border-bottom: 8px solid rgba(18, 28, 41, 0.15) !important;
    top: -9px !important;
  }

  &:after {
    border-left: 10px solid transparent !important;
    border-right: 10px solid transparent !important;
    top: -8px !important;
    left: 50% !important;
    margin-left: -10px !important;
    border-bottom: 8px solid var(--colorPrimary) !important;
    border-bottom: 8px solid var(--colorWhite) !important;
  }
}

.tooltipFilterItem {
  position: absolute;
  top: 26px;
  left: 0;
  max-width: 364px;

  @media (max-width: 2251px) {
    max-width: 268px;
  }

  @media (max-width: 1579px) {
    max-width: 236px;
  }

  @media (--tablet) {
    width: 100%;
    max-width: none;
  }

  > div {
    &:before {
      content: ' ';
      position: absolute;
      top: -6px;
      right: 9px;
      background: var(--colorWhite);
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      box-shadow: -2px -2px 3px -2px #121C2940;
    }
  }
}

.checkboxLabelFilterItem {
  max-width: 80%;
}
